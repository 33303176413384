<template>
  <div class="advanced-search-div" :style="`height:${clientHeight - 44}px`">
    <div class="advanced-search-box" v-show="isShowSearchBox">
      <div class="advanced-search-content">
        <slot />
      </div>
      <div class="btn-div">
        <span class="search-btn-span" @click="onAdvancedSearch"><label>搜索</label></span>
        <span class="search-btn-span" @click="onChangeAdvancedSearch"><label>取消</label></span>
      </div>
    </div>
    <div class="show-search-box" v-show="!isShowSearchBox" :style="`top:${(clientHeight - 44 - 100) / 2}px`" @click="onChangeAdvancedSearch">高<br>级<br>搜<br>索</div>
  </div>
</template>

<script>
export default {
  name: "AdvancedSearchBox",
  data() {
    return {
      clientHeight: 0,
      isShowSearchBox: false
    }
  },
  mounted() {
    this.clientHeight = document.documentElement.clientHeight;
    window.onresize = () => {
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
      })()
    };
  },
  methods: {
    onChangeAdvancedSearch() {
      this.isShowSearchBox = !this.isShowSearchBox;
    },
    onAdvancedSearch() {
      this.$emit("search");
      this.isShowSearchBox = false;
    }
  }
}
</script>

<style lang="less" scoped>
.advanced-search-div {
  position: absolute;
  top: 44px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .advanced-search-box {
    width: 230px;
    height: 100%;
    border-left: 1px solid #d7d7d7;
    background-color: #f5f5f5;
    .advanced-search-content {
      padding: 5px 10px;
      .select {
        width: 100%;
        height: 35px;
        border: 1px solid #dcdcdc;
      }
      .advanced-search-title {
        font-size: 16px;
        font-weight: bold;
      }
      .group-item-div {
        padding-top: 10px;
        .group-title-div {
          font-weight: bold;
          padding-bottom: 5px;
        }
        .item-div {
          margin-bottom: 5px;
        }
        .item-div:last-child {
          margin-bottom: 0;
        }
      }
    }
    .btn-div {
      width: 230px;
      height: 80px;
      position: fixed;
      bottom: 0;
      border-top: 1px solid #d7d7d7;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .search-btn-span {
        width: 60px;
        height: 25px;
        line-height: 25px;
        display: block;
        text-align: center;
        color: white;
        cursor: pointer;
      }
      .search-btn-span:first-child {
        margin-right: 30px;
        background-color: #4676c8;
      }
      .search-btn-span:last-child {
        background-color: #ee9900;
      }
      .search-btn-span:active {
        background-color: #787878;
      }
    }
  }
  .show-search-box {
    padding: 8px 5px;
    background-color: #32B16C;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
}
</style>