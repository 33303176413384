<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="user-msg-div">
        <div class="item-div item-field-div">
          <div>编号：{{ userInfo.id }}</div>
          <div>账号：{{ userInfo.acc }}</div>
        </div>
        <div class="item-vip-div item-field-div">
          <div>昵称：{{ userInfo.nickname }}</div>
        </div>
        <div class="vip-time-div">
          <div>
            <label>开通（续签）VIP时间：</label>
            <label>
              <select v-model="vipType">
                <option value="1">3天</option>
                <option value="2">7天</option>
                <option value="3">1个月</option>
                <option value="4">3个月</option>
                <option value="5">半年</option>
                <option value="6">1年</option>
              </select>
            </label>
          </div>
        </div>
        <div class="btn-div">
          <input type="button" value="确定" @click="onEnter">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postUserVipAdd } from "@/common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    userInfo: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      vipType: "1"
    }
  },
  methods: {
    onEnter() {
      postUserVipAdd({ userId: this.userInfo.id, type: this.vipType })
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          this.$layer.close(this.layerid);
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    display: flex;
    flex-direction: row;
    margin: 10px auto 0 auto;
    .user-msg-div {
      flex: 1;
      padding: 0 10px;
      .item-div {
        margin-top: 10px;
      }
      .item-div:first-child {
        margin-top: 0;
      }
      .item-field-div {
        display: flex;
        justify-content: space-between;
        div {
          width: 50%;
        }
      }
      .vip-time-div {
        margin-top: 20px;
        margin-bottom: 30px;
        text-align: center;
        select {
          height: 25px;
          border: 1px solid #dcdcdc;
        }
      }
      .btn-div {
        text-align: center;
        input {
          width: 120px;
          height: 35px;
          color: white;
          font-size: 16px;
          border: none;
          background: #4676c8;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>