<template>
  <div class="child-page-div">
    <advanced-search-box @search="onAdvancedSearch">
      <template>
        <div class="advanced-search-title">高级搜索</div>
        <div class="group-item-div">
          <div class="group-title-div">地区</div>
          <div class="item-div">
            <label>
              <select class="select" v-model="provinceId" @change="onProvinceCity($event)">
                <option value="">全部省份</option>
                <option v-for="item in prefecture.provinces" :value="item.id">{{item.name}}</option>
              </select>
            </label>
          </div>
          <div class="item-div">
            <label>
              <select class="select" v-model="cityId" @change="onProvinceCounty($event)">
                <option value="">全部城市</option>
                <option v-for="item in prefecture.cities" :value="item.id">{{item.name}}</option>
              </select>
            </label>
          </div>
          <div class="item-div">
            <label>
              <select class="select" v-model="areaId">
                <option value="">全部地区</option>
                <option v-for="item in prefecture.counties" :value="item.id">{{item.name}}</option>
              </select>
            </label>
          </div>
        </div>
        <div class="group-item-div">
          <div class="group-title-div">用户类型</div>
          <div class="item-div">
            <label>
              <select class="select" v-model="userType" @change="onUserTypeChange">
                <option value="">全部端</option>
                <option value="1">用户端</option>
                <option value="2">商家端</option>
                <option value="3">服务端</option>
              </select>
            </label>
          </div>
        </div>
        <div class="group-item-div" v-if="userType === '2' || userType === '3'">
          <div class="group-title-div">角色类型</div>
          <div class="item-div">
            <label>
              <select class="select" v-model="roleType">
                <option value="">全部端</option>
                <option v-for="item in userType === '2' ? roleTypes.merchant : roleTypes.provider" :value="item.type">{{ item.name }}</option>
              </select>
            </label>
          </div>
        </div>
      </template>
    </advanced-search-box>
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'用户管理'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select v-model="searchType">
                <option value="1">账号</option>
                <option value="2">昵称</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <span v-if="$validateAuthority(2100000,2101000,2101002)" class="cursor-el" @click="onShowMemberDetails(props.item.id)">查看</span>
                <span v-if="$validateAuthority(2100000,2101000,2101003)" class="cursor-el" @click="onAddVip(props.item)">{{ props.item.vipState === 1 ? "续签VIP" : "开通VIP"}}</span>
                <span v-if="$validateAuthority(2100000,2101000,2101001)" class="cursor-el" @click="onDelDetails(props.item)">删除</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import AdvancedSearchBox from "../../components/AdvancedSearchBox";
import TableBox from "../../components/TableBox";
import MemberDetailsLayer from "../layer/MemberDetailsLayer";
import MemberVipLayer from "../layer/MemberVipLayer";
import { getProvinces, getCities, getCounties, getUserItems, postUserDel } from "@/common/api";

export default {
  name: "Members",
  components: { AdvancedSearchBox, TableBox },
  data() {
    return {
      action: getUserItems,
      reloadData: false,
      prefecture: {
        provinces: [],
        cities: [],
        counties: []
      },
      provinceId: "",
      cityId: "",
      areaId: "",
      userType: "",
      roleType: "",
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        userType: "",
        roleType: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "账号", name: "acc", title: "acc", isHtml: true, value: this.getObjValue },
        { state: true, alias: "昵称", name: "nickname", title: "nickname", isHtml: false, value: this.getObjValue },
        { state: true, alias: "类型", name: "type", title: "type", isHtml: false, value: this.getObjValue },
        { state: true, alias: "角色", name: "role", title: "role", isHtml: false, value: this.getObjValue },
        { state: true, alias: "创建时间", name: "createTime", title: "createTime", isHtml: false, value: this.getObjValue }
      ],
      roleTypes: {
        merchant: [
          {type: "11", name: "配件店"},
          {type: "12", name: "生产厂家"},
          {type: "13", name: "代理商配件部"}
        ],
        provider: [
          {type: "21", name: "修理工"},
          {type: "22", name: "驾驶员"},
          {type: "23", name: "拖车"},
          {type: "24", name: "车床加工"},
          {type: "25", name: "电焊"},
          {type: "26", name: "压油管"},
          {type: "27", name: "五金店"},
          {type: "28", name: "销售员"},
          {type: "29", name: "物流"},
          {type: "30", name: "叉车"},
          {type: "31", name: "空压机"}
        ]
      }
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2100000,2101000,[2101001, 2101002, 2101003]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  created() {
    this.initProvinceData();
  },
  methods: {
    getObjValue(item, field) {
      if(field === "acc"){
        return `<span ${item["vipState"] === 1 ? "class='success-txt'" : ""}>${item[field]}</span>`;
      }else{
        return item[field];
      }
    },
    initProvinceData() {
      getProvinces()
          .then(data => {
            this.prefecture.provinces = data;
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询省份信息失败，请稍后重试");
            }
          });
    },
    onProvinceCity(event) {
      let provinceId = event.target.value;
      if(provinceId > 0){
        getCities({provinceId: provinceId})
            .then(data => {
              this.prefecture.cities = data;
              this.prefecture.counties = [];
              this.cityId = "";
              this.areaId = "";
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("查询城市信息失败，请稍后重试");
              }
            });
      }else{
        this.prefecture.cities = [];
        this.prefecture.counties = [];
        this.cityId = "";
        this.areaId = "";
      }
    },
    onProvinceCounty(event) {
      let cityId = event.target.value;
      if(cityId > 0){
        getCounties({cityId: cityId})
            .then(data => {
              this.prefecture.counties = data;
              this.areaId = "";
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("查询地区信息失败，请稍后重试");
              }
            });
      }else{
        this.prefecture.counties = [];
        this.areaId = "";
      }
    },
    onUserTypeChange() {
      this.roleType = "";
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
      this.queryParams.userType = "";
      this.queryParams.roleType = "";
      this.queryParams.provinceId = "";
      this.queryParams.cityId = "";
      this.queryParams.areaId = "";
    },
    onAdvancedSearch() {
      this.queryParams.type = 0;
      this.queryParams.msg = "";
      this.queryParams.userType = this.userType;
      this.queryParams.roleType = this.roleType;
      this.queryParams.provinceId = this.provinceId;
      this.queryParams.cityId = this.cityId;
      this.queryParams.areaId = this.areaId;
    },
    onShowMemberDetails(userId) {
      this.$layer.iframe({
        content: {
          content: MemberDetailsLayer,
          parent: this,
          data: {
            userId: userId
          }
        },
        area: ["800px", "400px"],
        title: "会员信息"
      });
    },
    onDelDetails(item) {
      this.$layer.confirm(`是否删除用户【${item.acc}】？`, layerid => {
        postUserDel({ userId: item.id })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    },
    onAddVip(item) {
      this.$layer.iframe({
        content: {
          content: MemberVipLayer,
          parent: this,
          data: {
            userInfo: item,
            refreshData: this.onReloadData
          }
        },
        area: ["600px", "300px"],
        title: "开通（续签）VIP"
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .state-label {
        margin-left: 5px;
        margin-right: 10px;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
    .operation-th {
      width: 120px;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>