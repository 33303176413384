<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <div class="user-avatar-div">
        <div class="avatar-div">
          <img v-if="Object.prototype.hasOwnProperty.call(userInfo, 'avatar')" :src="`${$imgBaseURL}/${userInfo.avatar}`" alt="" @click="onShowBigImgDiv($event)"/>
        </div>
        <div class="text-c" v-show="Object.prototype.hasOwnProperty.call(userInfo, 'vipExpireDate')">VIP ({{ userInfo.vipExpireDate }})</div>
      </div>
      <div class="user-msg-div">
        <div class="item-div item-field-div">
          <div>编号：{{ userInfo.id }}</div>
          <div>账号：{{ userInfo.acc }}</div>
        </div>
        <div class="item-div item-field-div">
          <div>昵称：{{ userInfo.nickname }}</div>
          <div>类型：{{ userInfo.type }}</div>
        </div>
        <div class="item-div item-field-div">
          <div>角色：{{ Object.prototype.hasOwnProperty.call(userInfo, 'role') ? userInfo.role : "-" }}</div>
          <div>联系电话：{{ Object.prototype.hasOwnProperty.call(userInfo, 'mobile') ? userInfo.mobile : "-" }}</div>
        </div>
        <div class="item-div item-field-div">
          <div>地区：{{ userInfo.address }}</div>
          <div>创建时间：{{ userInfo.createTime }}</div>
        </div>
        <div class="item-div introduce-div">
          <div>简介</div>
          <div>
            <label>
              <textarea v-model="userInfo.introduce" disabled></textarea>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserDetails } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    userId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      userInfo: {},
      isShowBigImg: false
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getUserDetails({ userId: this.userId })
          .then(data => {
            this.userInfo = data;
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询用户信息失败");
            }
            this.$layer.close(this.layerid);
          });
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 95%;
    display: flex;
    flex-direction: row;
    margin: 10px auto 0 auto;
    .user-avatar-div {
      width: 130px;
      .avatar-div {
        width: 128px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dee0e2;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          align-items: center;
        }
      }
    }
    .user-msg-div {
      flex: 1;
      padding: 0 10px;
      .item-div {
        margin-top: 10px;
      }
      .item-div:first-child {
        margin-top: 0;
      }
      .item-field-div {
        display: flex;
        justify-content: space-between;
        div {
          width: 50%;
        }
      }
      .introduce-div {
        textarea {
          width: calc(100% - 10px);
          height: 150px;
          margin-top: 5px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
      }
    }
  }
}
</style>